<template>
    <div class="full-height-layout fill">
        <router-view ref="routeview" :organisation-accounts="organisationAccounts" class="full-height-layout fill"></router-view>
    </div>
</template>

<script>
import CONFIG from '@root/config'
export default {
    name: 'QuotesView',
    data () {
        return {
            organisationAccounts: null,
        }
    },
    created () {
        const url = CONFIG.API.ROUTES.ACCESS_CONTROL.OVERVIEW_FOR_ORGANISATION.replace('{organisationId}', this.$store.state.auth.user.organisationId)
        this.addJob(url)
        this.$api.post(url, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
            this.organisationAccounts = data.data.items.map(account => {
                return {
                    ...account,
                    fullName: `${account.firstName || ''} ${account.lastName || ''}`,
                }
            })
        }).catch(data => {
            this.$root.$emit('notification:global', {
                message: `Getting available accounts failed. Please try again.`,
                type: 'error',
                errors: data,
            })
        }).finally(() => {
            this.finishJob(url)
        })
    },
}
</script>
